import { writable } from 'svelte/store';

// function createTopoData() {
//     const { subscribe, set, update} = writable([]);

//     return{
//         subscribe,
//         addData: data => update(n => n.push(data)),
//         reset: () => set([]),
//     }
// }

// export const topoData = createTopoData();

export const topoData = writable([]);

export const project = writable({});

export const projects = writable(0);

export const projectSettings = writable({
  firstTime: false,
});
