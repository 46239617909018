export const baseUrl = 'https://cms.echo-tool.ch';
const token = '32efa3a3344dc4fbbb06d599c84d18';

export async function getCollectionData(
  collectionName,
  filter = null,
  populate = true
) {
  let response = await fetch(
    `${baseUrl}/api/collections/get/${collectionName}?token=${token}`,
    {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        filter: filter,
        sort: { date: -1 },
        populate: populate,
      }),
    }
  );
  let data = await response.json();

  return data;
}

export async function saveCollection(collectionName, projectData) {
  let response = await fetch(
    `${baseUrl}/api/collections/save/${collectionName}?token=${token}`,
    {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: projectData,
      }),
    }
  );

  let data = await response.json();

  return data;
}

export async function removeCollection(collectionName, filter = null) {
  let response = await fetch(
    `${baseUrl}/api/collections/remove/${collectionName}?token=${token}`,
    {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        filter: filter,
      }),
    }
  );
  let data = await response.json();

  return data;
}

export async function getSingleton(name) {
  let response = await fetch(
    `${baseUrl}/api/singletons/get/${name}?token=${token}`
  );
  let data = await response.json();

  return data;
}
