/* components/LandingPage.svelte generated by Svelte v3.24.0 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

import { getContext } from "svelte";
import { project } from "../store.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[6] = list[i];
	return child_ctx;
}

// (20:4) {#each $project.users as user}
function create_each_block(ctx) {
	let button;
	let t_value = /*user*/ ctx[6].name + "";
	let t;
	let mounted;
	let dispose;

	function click_handler_1(...args) {
		return /*click_handler_1*/ ctx[4](/*user*/ ctx[6], ...args);
	}

	return {
		c() {
			button = element("button");
			t = text(t_value);
			attr(button, "class", "btn-text");
		},
		m(target, anchor) {
			insert(target, button, anchor);
			append(button, t);

			if (!mounted) {
				dispose = listen(button, "click", click_handler_1);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*$project*/ 2 && t_value !== (t_value = /*user*/ ctx[6].name + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let button0;
	let t1;
	let h2;
	let t2_value = /*$project*/ ctx[1].project + "";
	let t2;
	let t3;
	let t4;
	let button1;
	let mounted;
	let dispose;
	let each_value = /*$project*/ ctx[1].users;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			button0 = element("button");
			button0.textContent = "settings";
			t1 = space();
			h2 = element("h2");
			t2 = text(t2_value);
			t3 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t4 = space();
			button1 = element("button");
			button1.textContent = "terrain";
			attr(button0, "class", "absolute top-0 right-0 m-4 btn-icon material-icons");
			attr(h2, "class", " font-bold uppercase mb-12");
			attr(button1, "class", "h-16 w-16 btn-icon mt-6 text-4xl material-icons");
			attr(div0, "class", "absolute flex flex-col flex-grow justify-center items-center py-6 min-h-full w-full");
			attr(div1, "class", "relative w-full h-full");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, button0);
			append(div0, t1);
			append(div0, h2);
			append(h2, t2);
			append(div0, t3);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div0, null);
			}

			append(div0, t4);
			append(div0, button1);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler*/ ctx[3]),
					listen(button1, "click", /*click_handler_2*/ ctx[5])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*$project*/ 2 && t2_value !== (t2_value = /*$project*/ ctx[1].project + "")) set_data(t2, t2_value);

			if (dirty & /*navigate, id, $project*/ 7) {
				each_value = /*$project*/ ctx[1].users;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div0, t4);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
			destroy_each(each_blocks, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $project;
	component_subscribe($$self, project, $$value => $$invalidate(1, $project = $$value));
	const navigate = getContext("navigate");
	let { id } = $$props;
	const click_handler = () => navigate(`/project/${id}/project-setup`);
	const click_handler_1 = user => navigate(`/project/${id}/${user._id}/topo-input`);
	const click_handler_2 = () => navigate(`/project/${id}/topo-overview`);

	$$self.$set = $$props => {
		if ("id" in $$props) $$invalidate(0, id = $$props.id);
	};

	return [id, $project, navigate, click_handler, click_handler_1, click_handler_2];
}

class LandingPage extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { id: 0 });
	}
}

export default LandingPage;