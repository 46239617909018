/* components/Project.svelte generated by Svelte v3.24.0 */
import {
	SvelteComponent,
	assign,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	get_spread_object,
	get_spread_update,
	group_outros,
	handle_promise,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	set_store_value,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import { onMount } from "svelte";
import TopoInput from "./TopoInput.svelte";
import TopoOverview from "./TopoOverview.svelte";
import ProjectSetup from "./ProjectSetup.svelte";
import LandingPage from "./LandingPage.svelte";
import Router from "svelte-navaid/Router.svelte";
import Route from "svelte-navaid/Route.svelte";
import navaidHash from "svelte-navaid/navaid-hash";
import { project } from "../store.js";
import { getCollectionData } from "../cmsConnector.js";

function create_catch_block(ctx) {
	return {
		c: noop,
		m: noop,
		p: noop,
		i: noop,
		o: noop,
		d: noop
	};
}

// (37:0) {:then data}
function create_then_block(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*$project*/ ctx[0].users && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (/*$project*/ ctx[0].users) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*$project*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (38:2) {#if $project.users}
function create_if_block(ctx) {
	let router;
	let current;

	router = new Router({
			props: {
				library: navaidHash,
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(router.$$.fragment);
		},
		m(target, anchor) {
			mount_component(router, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const router_changes = {};

			if (dirty & /*$$scope*/ 128) {
				router_changes.$$scope = { dirty, ctx };
			}

			router.$set(router_changes);
		},
		i(local) {
			if (current) return;
			transition_in(router.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(router.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(router, detaching);
		}
	};
}

// (40:6) <Route path="/:id/project-setup" let:params>
function create_default_slot_4(ctx) {
	let projectsetup;
	let current;
	const projectsetup_spread_levels = [/*params*/ ctx[6]];
	let projectsetup_props = {};

	for (let i = 0; i < projectsetup_spread_levels.length; i += 1) {
		projectsetup_props = assign(projectsetup_props, projectsetup_spread_levels[i]);
	}

	projectsetup = new ProjectSetup({ props: projectsetup_props });

	return {
		c() {
			create_component(projectsetup.$$.fragment);
		},
		m(target, anchor) {
			mount_component(projectsetup, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const projectsetup_changes = (dirty & /*params*/ 64)
			? get_spread_update(projectsetup_spread_levels, [get_spread_object(/*params*/ ctx[6])])
			: {};

			projectsetup.$set(projectsetup_changes);
		},
		i(local) {
			if (current) return;
			transition_in(projectsetup.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(projectsetup.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(projectsetup, detaching);
		}
	};
}

// (44:6) <Route path="/:id" let:params>
function create_default_slot_3(ctx) {
	let landingpage;
	let current;
	const landingpage_spread_levels = [/*params*/ ctx[6]];
	let landingpage_props = {};

	for (let i = 0; i < landingpage_spread_levels.length; i += 1) {
		landingpage_props = assign(landingpage_props, landingpage_spread_levels[i]);
	}

	landingpage = new LandingPage({ props: landingpage_props });

	return {
		c() {
			create_component(landingpage.$$.fragment);
		},
		m(target, anchor) {
			mount_component(landingpage, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const landingpage_changes = (dirty & /*params*/ 64)
			? get_spread_update(landingpage_spread_levels, [get_spread_object(/*params*/ ctx[6])])
			: {};

			landingpage.$set(landingpage_changes);
		},
		i(local) {
			if (current) return;
			transition_in(landingpage.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(landingpage.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(landingpage, detaching);
		}
	};
}

// (48:6) <Route path="/:id/:user/topo-input" let:params>
function create_default_slot_2(ctx) {
	let topoinput;
	let current;
	const topoinput_spread_levels = [/*params*/ ctx[6]];
	let topoinput_props = {};

	for (let i = 0; i < topoinput_spread_levels.length; i += 1) {
		topoinput_props = assign(topoinput_props, topoinput_spread_levels[i]);
	}

	topoinput = new TopoInput({ props: topoinput_props });

	return {
		c() {
			create_component(topoinput.$$.fragment);
		},
		m(target, anchor) {
			mount_component(topoinput, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const topoinput_changes = (dirty & /*params*/ 64)
			? get_spread_update(topoinput_spread_levels, [get_spread_object(/*params*/ ctx[6])])
			: {};

			topoinput.$set(topoinput_changes);
		},
		i(local) {
			if (current) return;
			transition_in(topoinput.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(topoinput.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(topoinput, detaching);
		}
	};
}

// (52:6) <Route path="/:id/topo-overview" let:params>
function create_default_slot_1(ctx) {
	let topooverview;
	let current;
	const topooverview_spread_levels = [/*params*/ ctx[6]];
	let topooverview_props = {};

	for (let i = 0; i < topooverview_spread_levels.length; i += 1) {
		topooverview_props = assign(topooverview_props, topooverview_spread_levels[i]);
	}

	topooverview = new TopoOverview({ props: topooverview_props });

	return {
		c() {
			create_component(topooverview.$$.fragment);
		},
		m(target, anchor) {
			mount_component(topooverview, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const topooverview_changes = (dirty & /*params*/ 64)
			? get_spread_update(topooverview_spread_levels, [get_spread_object(/*params*/ ctx[6])])
			: {};

			topooverview.$set(topooverview_changes);
		},
		i(local) {
			if (current) return;
			transition_in(topooverview.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(topooverview.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(topooverview, detaching);
		}
	};
}

// (39:4) <Router library="{navaidHash}">
function create_default_slot(ctx) {
	let route0;
	let t0;
	let route1;
	let t1;
	let route2;
	let t2;
	let route3;
	let current;

	route0 = new Route({
			props: {
				path: "/:id/project-setup",
				$$slots: {
					default: [
						create_default_slot_4,
						({ params }) => ({ 6: params }),
						({ params }) => params ? 64 : 0
					]
				},
				$$scope: { ctx }
			}
		});

	route1 = new Route({
			props: {
				path: "/:id",
				$$slots: {
					default: [
						create_default_slot_3,
						({ params }) => ({ 6: params }),
						({ params }) => params ? 64 : 0
					]
				},
				$$scope: { ctx }
			}
		});

	route2 = new Route({
			props: {
				path: "/:id/:user/topo-input",
				$$slots: {
					default: [
						create_default_slot_2,
						({ params }) => ({ 6: params }),
						({ params }) => params ? 64 : 0
					]
				},
				$$scope: { ctx }
			}
		});

	route3 = new Route({
			props: {
				path: "/:id/topo-overview",
				$$slots: {
					default: [
						create_default_slot_1,
						({ params }) => ({ 6: params }),
						({ params }) => params ? 64 : 0
					]
				},
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(route0.$$.fragment);
			t0 = space();
			create_component(route1.$$.fragment);
			t1 = space();
			create_component(route2.$$.fragment);
			t2 = space();
			create_component(route3.$$.fragment);
		},
		m(target, anchor) {
			mount_component(route0, target, anchor);
			insert(target, t0, anchor);
			mount_component(route1, target, anchor);
			insert(target, t1, anchor);
			mount_component(route2, target, anchor);
			insert(target, t2, anchor);
			mount_component(route3, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const route0_changes = {};

			if (dirty & /*$$scope, params*/ 192) {
				route0_changes.$$scope = { dirty, ctx };
			}

			route0.$set(route0_changes);
			const route1_changes = {};

			if (dirty & /*$$scope, params*/ 192) {
				route1_changes.$$scope = { dirty, ctx };
			}

			route1.$set(route1_changes);
			const route2_changes = {};

			if (dirty & /*$$scope, params*/ 192) {
				route2_changes.$$scope = { dirty, ctx };
			}

			route2.$set(route2_changes);
			const route3_changes = {};

			if (dirty & /*$$scope, params*/ 192) {
				route3_changes.$$scope = { dirty, ctx };
			}

			route3.$set(route3_changes);
		},
		i(local) {
			if (current) return;
			transition_in(route0.$$.fragment, local);
			transition_in(route1.$$.fragment, local);
			transition_in(route2.$$.fragment, local);
			transition_in(route3.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(route0.$$.fragment, local);
			transition_out(route1.$$.fragment, local);
			transition_out(route2.$$.fragment, local);
			transition_out(route3.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(route0, detaching);
			if (detaching) detach(t0);
			destroy_component(route1, detaching);
			if (detaching) detach(t1);
			destroy_component(route2, detaching);
			if (detaching) detach(t2);
			destroy_component(route3, detaching);
		}
	};
}

// (35:17)    <h3 class>Loading Project</h3> {:then data}
function create_pending_block(ctx) {
	let h3;

	return {
		c() {
			h3 = element("h3");
			h3.textContent = "Loading Project";
			attr(h3, "class", "");
		},
		m(target, anchor) {
			insert(target, h3, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h3);
		}
	};
}

function create_fragment(ctx) {
	let await_block_anchor;
	let promise;
	let current;

	let info = {
		ctx,
		current: null,
		token: null,
		pending: create_pending_block,
		then: create_then_block,
		catch: create_catch_block,
		value: 5,
		blocks: [,,,]
	};

	handle_promise(promise = /*$project*/ ctx[0], info);

	return {
		c() {
			await_block_anchor = empty();
			info.block.c();
		},
		m(target, anchor) {
			insert(target, await_block_anchor, anchor);
			info.block.m(target, info.anchor = anchor);
			info.mount = () => await_block_anchor.parentNode;
			info.anchor = await_block_anchor;
			current = true;
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;
			info.ctx = ctx;

			if (dirty & /*$project*/ 1 && promise !== (promise = /*$project*/ ctx[0]) && handle_promise(promise, info)) {
				
			} else {
				const child_ctx = ctx.slice();
				child_ctx[5] = info.resolved;
				info.block.p(child_ctx, dirty);
			}
		},
		i(local) {
			if (current) return;
			transition_in(info.block);
			current = true;
		},
		o(local) {
			for (let i = 0; i < 3; i += 1) {
				const block = info.blocks[i];
				transition_out(block);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(await_block_anchor);
			info.block.d(detaching);
			info.token = null;
			info = null;
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $project;
	component_subscribe($$self, project, $$value => $$invalidate(0, $project = $$value));
	let { wild } = $$props;
	let id;
	let result;

	async function getProject() {
		set_store_value(project, $project = await getCollectionData("Projects", { _id: id }));
		set_store_value(project, $project = $project.entries.pop());
		console.log($project);
	}

	onMount(() => {
		id = wild.split("/")[0];
		getProject();
	});

	$$self.$set = $$props => {
		if ("wild" in $$props) $$invalidate(1, wild = $$props.wild);
	};

	return [$project, wild];
}

class Project extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { wild: 1 });
	}
}

export default Project;