/* components/TopoInput.svelte generated by Svelte v3.24.0 */
import {
	SvelteComponent,
	add_render_callback,
	add_resize_listener,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	globals,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text
} from "svelte/internal";

const { document: document_1 } = globals;
import * as utils from "../utils.js";
import { project } from "../store.js";
import { saveCollection } from "../cmsConnector.js";

// import p5 from 'p5';
import { onMount, onDestroy, getContext } from "svelte";

import { topoData } from "../store";

function add_css() {
	var style = element("style");
	style.id = "svelte-1c4fvvd-style";
	style.textContent = "@keyframes svelte-1c4fvvd-pulsate{0%{-webkit-transform:scale(0.1, 0.1);opacity:0}50%{opacity:1}100%{-webkit-transform:scale(1.2, 1.2);opacity:0}}.indicator.svelte-1c4fvvd{animation:svelte-1c4fvvd-pulsate 1s ease-out;animation-iteration-count:infinite;opacity:0}canvas{z-index:0;position:fixed;user-select:none;cursor:pointer}";
	append(document_1.head, style);
}

// (532:2) {#if !hasStarted && $project && setupDone}
function create_if_block_3(ctx) {
	let div;

	return {
		c() {
			div = element("div");

			div.innerHTML = `<p class="font-bold text-center">How far did you get today?
        <br>
        How steep was the journey?</p> 
      <p class="font-normal pt-5 text-xs text-center">Drag the pin according to your perceived progress and challenges in the
        project.</p>`;

			attr(div, "class", "absolute w-64 h-48 bg-app-green rounded-lg p-4 text-white");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (546:2) {#if (!startFromOrigin || !hasStarted) && $project && setupDone}
function create_if_block_2(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			set_style(div, "position", "absolute");
			set_style(div, "top", /*fieldOrigin*/ ctx[7].y - 60 + "px");
			set_style(div, "left", "30px");
			set_style(div, "width", "60px");
			set_style(div, "height", "60px");
			attr(div, "class", "bg-black rounded-full indicator svelte-1c4fvvd");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*fieldOrigin*/ 128) {
				set_style(div, "top", /*fieldOrigin*/ ctx[7].y - 60 + "px");
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (555:4) {#if startFromOrigin}
function create_if_block_1(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "done";
			attr(button, "class", "btn-icon material-icons");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*handleSave*/ ctx[11]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (575:2) {#if hasStarted && startFromOrigin}
function create_if_block(ctx) {
	let p;
	let t_value = /*labels*/ ctx[10][/*intersection*/ ctx[3]] + "";
	let t;

	return {
		c() {
			p = element("p");
			t = text(t_value);
			attr(p, "class", "absolute bottom-0 left-0 m-4 text-white font-bold");
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*intersection*/ 8 && t_value !== (t_value = /*labels*/ ctx[10][/*intersection*/ ctx[3]] + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let div0;
	let div0_resize_listener;
	let t0;
	let t1;
	let t2;
	let div1;
	let t3;
	let button0;
	let t5;
	let button1;
	let t7;
	let mounted;
	let dispose;
	let if_block0 = !/*hasStarted*/ ctx[4] && /*$project*/ ctx[8] && /*setupDone*/ ctx[6] && create_if_block_3(ctx);
	let if_block1 = (!/*startFromOrigin*/ ctx[5] || !/*hasStarted*/ ctx[4]) && /*$project*/ ctx[8] && /*setupDone*/ ctx[6] && create_if_block_2(ctx);
	let if_block2 = /*startFromOrigin*/ ctx[5] && create_if_block_1(ctx);
	let if_block3 = /*hasStarted*/ ctx[4] && /*startFromOrigin*/ ctx[5] && create_if_block(ctx);

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			t0 = space();
			if (if_block0) if_block0.c();
			t1 = space();
			if (if_block1) if_block1.c();
			t2 = space();
			div1 = element("div");
			if (if_block2) if_block2.c();
			t3 = space();
			button0 = element("button");
			button0.textContent = "refresh";
			t5 = space();
			button1 = element("button");
			button1.textContent = "close";
			t7 = space();
			if (if_block3) if_block3.c();
			attr(div0, "id", "p5canvas");
			attr(div0, "class", "w-full h-full absolute");
			add_render_callback(() => /*div0_elementresize_handler*/ ctx[13].call(div0));
			attr(button0, "class", "btn-icon material-icons");
			attr(button1, "class", "btn-icon material-icons");
			attr(div1, "class", "absolute top-0 right-0 m-4 z-50 flex flex-row justify-end");
			attr(div2, "class", "w-full h-full relative flex flex-col justify-center items-center z-50");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			div0_resize_listener = add_resize_listener(div0, /*div0_elementresize_handler*/ ctx[13].bind(div0));
			append(div2, t0);
			if (if_block0) if_block0.m(div2, null);
			append(div2, t1);
			if (if_block1) if_block1.m(div2, null);
			append(div2, t2);
			append(div2, div1);
			if (if_block2) if_block2.m(div1, null);
			append(div1, t3);
			append(div1, button0);
			append(div1, t5);
			append(div1, button1);
			append(div2, t7);
			if (if_block3) if_block3.m(div2, null);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler*/ ctx[14]),
					listen(button1, "click", /*click_handler_1*/ ctx[15])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (!/*hasStarted*/ ctx[4] && /*$project*/ ctx[8] && /*setupDone*/ ctx[6]) {
				if (if_block0) {
					
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					if_block0.m(div2, t1);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if ((!/*startFromOrigin*/ ctx[5] || !/*hasStarted*/ ctx[4]) && /*$project*/ ctx[8] && /*setupDone*/ ctx[6]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					if_block1.m(div2, t2);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*startFromOrigin*/ ctx[5]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_1(ctx);
					if_block2.c();
					if_block2.m(div1, t3);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}

			if (/*hasStarted*/ ctx[4] && /*startFromOrigin*/ ctx[5]) {
				if (if_block3) {
					if_block3.p(ctx, dirty);
				} else {
					if_block3 = create_if_block(ctx);
					if_block3.c();
					if_block3.m(div2, null);
				}
			} else if (if_block3) {
				if_block3.d(1);
				if_block3 = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
			div0_resize_listener();
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

let resized = false;
let debug = false;
let fingerIndicator = false;

function instance($$self, $$props, $$invalidate) {
	let $project;
	component_subscribe($$self, project, $$value => $$invalidate(8, $project = $$value));
	const navigate = getContext("navigate");
	let { user } = $$props;
	let { id } = $$props;
	let width;
	let height;
	let userData;
	let tColors;
	let currentColor;
	let intersection = -1;
	let normalizedIndicator;
	let randomPatternPositions = [];
	let hasStarted = false;
	let startFromOrigin = false;
	let setupDone = false;
	let insideField = false;
	let canvasElement;
	let fieldSize;
	let fieldOrigin;
	let arcEndPoint;
	let arcStartPoint;
	let textureStack = [];
	let overlays = [];
	let textOverlay = ["How far did you get today?", "How steep was the journey?"];
	let day = new Date().getDay();

	let labels = [
		"Easy stroll",
		"Smooth travels",
		"Slight ascent",
		"Rocky ground",
		"Harsh terrain",
		"Brutal conditions"
	];

	function createRandomPattern(length) {
		let arr = [];

		for (let i = 0; i < length; i++) {
			let vec = createVector();
			vec.x = random(-100, width + 100);

			// randomSeed(20);
			vec.y = random(-100, height + 100);

			arr.push({ position: vec, offset: i % 3 });
		}

		return arr;
	}

	window.preload = function () {
		for (let i = 0; i < 18; i++) {
			loadImage(
				"/../../../patterns/" + i + ".png",
				img => {
					textureStack[i] = img;
				},
				error => {
					console.error(error);
				}
			);
		}
	};

	window.setup = function () {
		colorMode(HSL);
		let canvas = createCanvas(width, height);
		canvas.parent("p5canvas");
		canvasElement = document.getElementsByTagName("canvas")[0];
		fieldSize = width > height ? height / 2 : width / 2;
		arcStartPoint = utils.pointsOnCircle(2 * fieldSize, 1.5 * PI, 0, height);
		arcEndPoint = utils.pointsOnCircle(2 * fieldSize, 1.5 * PI + HALF_PI / 5 * 6, 0, height);
		normalizedIndicator = createVector();
		$$invalidate(7, fieldOrigin = createVector(0, height - arcEndPoint.y + height));
		arcEndPoint.y = height;

		tColors = [
			color(11, 91, 60, 0.8),
			color(331, 80, 72, 0.8),
			color(50, 100, 71, 0.8),
			color(101, 54, 74, 0.8),
			color(168, 40, 50, 0.8),
			color(228, 9, 44, 0.8)
		];

		currentColor = color(0, 0, 0, 0.8);
		randomPatternPositions.push(createRandomPattern(300));
		randomPatternPositions.push(createRandomPattern(300));
		randomPatternPositions.push(createRandomPattern(300));
		randomPatternPositions.push(createRandomPattern(300));
		randomPatternPositions.push(createRandomPattern(300));
		randomPatternPositions.push(createRandomPattern(300));
		randomPatternPositions.push(createRandomPattern(300));
		mouseX = fieldOrigin.x;
		mouseY = fieldOrigin.y;

		for (let i = 0; i < $project.users.length; i++) {
			if ($project.users[i]._id === user) {
				userData = $project.users[i];
				break;
			}
		}

		$$invalidate(6, setupDone = true);
		noLoop();
	};

	window.draw = function () {
		colorMode(RGB);
		background(217, 238, 212);
		noStroke();
		fill(255);

		// arc(fieldOrigin.x, fieldOrigin.y, 4 * fieldSize, 4 * fieldSize, 0, TWO_PI);
		colorMode(HSL);

		if (!hasStarted) {
			
		}

		/*****************************************************/
		// Draw background pattern
		/*****************************************************/
		let patternStackOffset = 3;

		if (intersection > -1) {
			// number ofer indicator = amount of pattern
			for (let i = 0; i < Math.floor(normalizedIndicator.x * 120); i++) {
				if (intersection == 0) {
					image(textureStack[randomPatternPositions[0][i].offset], randomPatternPositions[0][i].position.x, randomPatternPositions[0][i].position.y);
					image(textureStack[randomPatternPositions[6][i].offset], randomPatternPositions[6][i].position.x, randomPatternPositions[6][i].position.y);
				} else {
					image(textureStack[(intersection - 1) * patternStackOffset + randomPatternPositions[intersection - 1][i].offset], randomPatternPositions[intersection - 1][i].position.x, randomPatternPositions[intersection - 1][i].position.y);
					image(textureStack[intersection * patternStackOffset + randomPatternPositions[intersection][i].offset], randomPatternPositions[intersection][i].position.x, randomPatternPositions[intersection][i].position.y);
				}
			}
		}

		/*****************************************************/
		// Draw field
		/*****************************************************/
		if (currentColor) {
			push();

			// fill(currentColor);
			noStroke();

			let start = 1.5 * PI;

			for (let i = 0; i < 6; i++) {
				// strokeWeight(2);
				// stroke(255);
				if (!hasStarted) {
					fill(tColors[i]);
				} else {
					fill(currentColor);
				}

				// Vertical Arcs
				arc(fieldOrigin.x, fieldOrigin.y, 4 * fieldSize, 4 * fieldSize, start + HALF_PI / 5 * i, start + HALF_PI / 5 * (i + 1));
			} // Horizontal Arcs
			// arc(

			//   fieldOrigin.x,
			//   fieldOrigin.y,
			//   ((4 * fieldSize) / 6) * (i + 1),
			//   ((4 * fieldSize) / 6) * (i + 1),
			//   1.5 * PI,
			//   start + (HALF_PI / 5) * 6
			// );
			fill(0);

			// Draw black triangle at bottom
			beginShape();

			vertex(fieldOrigin.x, fieldOrigin.y);
			vertex(arcEndPoint.x, arcEndPoint.y);
			vertex(0, height);
			endShape(CLOSE);
			pop();
		}

		/*****************************************************/
		// Draw last 3 points if possible
		/*****************************************************/
		// normalizedIndicator.x = map(radius, 0, 2 * fieldSize, 0, 1);
		// normalizedIndicator.y = map(angle, -(PI / 2), 0, 1, 0);
		let lastFiveEntries = userData.data.slice(-4);

		if (lastFiveEntries.length <= 4) lastFiveEntries.shift();

		for (let i = 0; i < lastFiveEntries.length; i++) {
			// map(lastFiveEntries[i].x, 0, 1, 2 * fieldSize, 0);
			let unNormalize = createVector(map(lastFiveEntries[i].x, 0, 1, 0, 2 * fieldSize), map(lastFiveEntries[i].y, 0, 1, 0, -(PI / 2)));

			push();

			// noFill();
			strokeWeight(1);

			stroke(100, 100, 100, 1);

			// noStroke();
			fill(color(lastFiveEntries[i].color.h, lastFiveEntries[i].color.s, lastFiveEntries[i].color.b, lastFiveEntries[i].color.a));

			translate(fieldOrigin.x, fieldOrigin.y);
			rotate(unNormalize.y);
			translate(unNormalize.x, 0);
			ellipse(-5, -5, (i + 1) * 10);
			pop();
		}

		/*****************************************************/
		// Draw indicator
		/*****************************************************/
		if (hasStarted && startFromOrigin && insideField) {
			push();
			let triangleTopHeight = 20;
			let triangleWidth = 20;
			let radius = sqrt(sq(mouseX - fieldOrigin.x) + sq(mouseY - fieldOrigin.y));

			if (mouseY < fieldOrigin.y) {
				radius = radius * 1;
			} // radius = radius * chewIndicatorFactor;

			radius = constrain(radius, 0, 2 * fieldSize);
			let angle = atan2(mouseY - fieldOrigin.y, mouseX - fieldOrigin.x);
			angle = constrain(angle, -(PI / 2), PI / 10);
			let triangleBottomHeight;
			translate(fieldOrigin.x, fieldOrigin.y);
			rotate(angle);
			triangleBottomHeight = radius - triangleTopHeight;
			fill(0);
			noStroke();
			beginShape();
			vertex(0, 0);
			vertex(triangleBottomHeight, -triangleWidth / 2);
			vertex(triangleBottomHeight + triangleTopHeight, 0);
			vertex(triangleBottomHeight, triangleWidth / 2);
			endShape(CLOSE);

			if (fingerIndicator) {
				noFill();
				stroke(0);
				strokeWeight(2);
				ellipse(triangleBottomHeight + triangleTopHeight, 0, 20);
				ellipse(triangleBottomHeight + triangleTopHeight, 0, 40);
				ellipse(triangleBottomHeight + triangleTopHeight, 0, 60);
			}

			pop();
			normalizedIndicator.x = map(radius, 0, 2 * fieldSize, 0, 1);
			normalizedIndicator.y = map(angle, -(PI / 2), 0, 1, 0);
		}

		/*****************************************************/
		// Check segment intersections with mouse
		/*****************************************************/
		let mousePosVec = createVector(mouseX - fieldOrigin.x, mouseY - fieldOrigin.y);

		mousePosVec = mousePosVec.limit(2 * fieldSize);
		mousePosVec.x = mousePosVec.x + fieldOrigin.x;
		mousePosVec.y = mousePosVec.y + fieldOrigin.y;
		let start = 1.5 * PI;

		if (startFromOrigin) {
			for (let i = 0; i < 6; i++) {
				// Check if indicator is over Arc
				// Check for vertical Arcs
				if (utils.containsPoint(mousePosVec.x, mousePosVec.y, fieldOrigin.x, fieldOrigin.y, 1.5 * PI, start + (HALF_PI + HALF_PI / 5) / 6 * (i + 1), 2 * fieldSize)) {
					currentColor = tColors[i];
					currentColor = color(currentColor._getHue(), map(normalizedIndicator.x, 0, 1, 0, currentColor._getSaturation()), currentColor._getLightness(), currentColor._getAlpha());

					// currentColor._array[1] = normalizedIndicator.x;
					$$invalidate(3, intersection = 5 - i);

					break;
				}
			}
		} else {
			currentColor = color(0, 0, 0, 0.4);
		}
	};

	window.windowResized = function () {
		randomPatternPositions = [];
		setup();
		draw();
	};

	window.mousePressed = function () {
		if (utils.containsPoint(mouseX, mouseY, fieldOrigin.x, fieldOrigin.y, 0.001, TWO_PI, 2 * fieldSize)) {
			insideField = true;
			loop();

			if (utils.containsPoint(mouseX, mouseY, fieldOrigin.x + 30, fieldOrigin.y - 60, 0.001, TWO_PI, 60)) {
				$$invalidate(4, hasStarted = true);
				$$invalidate(5, startFromOrigin = true);
			} else {
				$$invalidate(5, startFromOrigin = false);
				mouseX = fieldOrigin.x;
				mouseY = fieldOrigin.y;
				setup();
			}
		} else {
			insideField = false;
			noLoop();
		}
	};

	window.mouseReleased = function () {
		noLoop();
	};

	// Svelte functions
	function handlePointerEvent(event) {
		if (event.pointerType === "touch" && insideField) {
			mouseX = event.pageX;
			mouseY = event.pageY;
		}
	}

	onMount(() => {
		new p5();
		document.addEventListener("pointermove", handlePointerEvent);
		window.scrollTo(0, 1);
	});

	onDestroy(() => {
		delete window.setup;
		delete window.draw;
		delete window.preload;
		delete window.windowResized;
		delete window.mousePressed;
		delete window.mouseReleased;
		remove();
	});

	async function handleSave() {
		for (let i = 0; i < $project.users.length; i++) {
			if ($project.users[i]._id === user) {
				let userData = $project.users[i];

				userData.data.push({
					x: normalizedIndicator.x,
					y: normalizedIndicator.y,
					color: {
						h: currentColor._getHue(),
						s: currentColor._getSaturation(),
						b: currentColor._getLightness(),
						a: 1
					}
				});

				let result = await saveCollection("Users", userData);
				console.log(result);
			}

			navigate(`/project/${id}/topo-overview`);
		}
	}

	function div0_elementresize_handler() {
		height = this.offsetHeight;
		width = this.offsetWidth;
		$$invalidate(2, height);
		$$invalidate(1, width);
	}

	const click_handler = () => {
		$$invalidate(4, hasStarted = false);
		$$invalidate(5, startFromOrigin = false);
		setup();
		draw();
	};

	const click_handler_1 = () => navigate(`/project/${id}`);

	$$self.$set = $$props => {
		if ("user" in $$props) $$invalidate(12, user = $$props.user);
		if ("id" in $$props) $$invalidate(0, id = $$props.id);
	};

	return [
		id,
		width,
		height,
		intersection,
		hasStarted,
		startFromOrigin,
		setupDone,
		fieldOrigin,
		$project,
		navigate,
		labels,
		handleSave,
		user,
		div0_elementresize_handler,
		click_handler,
		click_handler_1
	];
}

class TopoInput extends SvelteComponent {
	constructor(options) {
		super();
		if (!document_1.getElementById("svelte-1c4fvvd-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { user: 12, id: 0 }, [-1, -1]);
	}
}

export default TopoInput;