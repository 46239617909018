/* components/Start.svelte generated by Svelte v3.24.0 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_store_value,
	set_style,
	space
} from "svelte/internal";

import { onMount, onDestroy, getContext } from "svelte";
import { project, projects } from "../store.js";
import { saveCollection } from "../cmsConnector.js";
import slugify from "slugify";
import welcome from "../../assets/welcome.png";

function create_fragment(ctx) {
	let div;
	let h1;
	let t1;
	let h2;
	let t3;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			h1 = element("h1");
			h1.textContent = "Echo";
			t1 = space();
			h2 = element("h2");
			h2.textContent = "Making collaboration tangible";
			t3 = space();
			button = element("button");
			button.textContent = "Create New Project";
			attr(h2, "class", "mb-20");
			attr(button, "class", "btn-text");
			attr(div, "class", "w-full h-full flex flex-col justify-center items-center bg-cover bg-center");
			set_style(div, "background-image", "url(" + welcome + ")");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h1);
			append(div, t1);
			append(div, h2);
			append(div, t3);
			append(div, button);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler*/ ctx[1]);
				mounted = true;
			}
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

let newProject = false;
let openExisting = false;
let projectName = "";

function instance($$self, $$props, $$invalidate) {
	let $projects;
	let $project;
	component_subscribe($$self, projects, $$value => $$invalidate(4, $projects = $$value));
	component_subscribe($$self, project, $$value => $$invalidate(5, $project = $$value));
	const navigate = getContext("navigate");
	let projectId;
	let existing;

	async function createProject() {
		let result = await saveCollection("Projects", {
			users: [],
			project: "",
			"project-slug": ""
		});

		projectId = result._id;
		console.log(projectId);
		navigate(`/project/${projectId}/project-setup`);
	}

	const click_handler = () => createProject();

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$projects*/ 16) {
			$: if (projectName) {
				console.log($projects);

				for (let i = 0; i < $projects.entries.length; i++) {
					if ($projects.entries[i]["project-slug"] === slugify(projectName, { locale: "de", lower: true })) {
						existing = true;
						set_store_value(project, $project = $projects.entries[i]);
						break;
					} else {
						existing = false;
					}
				}
			}
		}
	};

	return [createProject, click_handler];
}

class Start extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Start;