import App from './App.svelte';

// setting up polyfills for smooth scroll and older browsers
import smoothscroll from 'smoothscroll-polyfill';
import '@babel/polyfill';

smoothscroll.polyfill();

window.addEventListener("contextmenu", e => e.preventDefault());

// projects.set(getCollectionData('Projects'));

const app = new App({
  target: document.body,
});

export default app;
