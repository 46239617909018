/* App.svelte generated by Svelte v3.24.0 */
import {
	SvelteComponent,
	add_flush_callback,
	append,
	assign,
	attr,
	bind,
	binding_callbacks,
	create_component,
	destroy_component,
	detach,
	element,
	get_spread_object,
	get_spread_update,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_style,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import { onMount } from "svelte";
import Start from "./components/Start.svelte";
import CamTest from "./components/CamTest.svelte";
import Project from "./components/Project.svelte";
import Router from "svelte-navaid/Router.svelte";
import Route from "svelte-navaid/Route.svelte";
import navaidHash from "svelte-navaid/navaid-hash";
import { getCollectionData } from "./cmsConnector";
import welcome from "../assets/welcome.png";

function add_css() {
	var style = element("style");
	style.id = "svelte-t24l8e-style";
	style.textContent = "#defaultCanvas0{@apply rounded-lg;}.canvas.svelte-t24l8e{width:100%;height:100%;max-width:500px;max-height:800px;@apply mx-auto;;@apply my-auto;;@apply overflow-y-auto;;box-shadow:black 0px 0px 0px 5px;@apply rounded-lg;;@apply bg-center;;@apply bg-cover;}";
	append(document.head, style);
}

// (55:6) <Route path="/">
function create_default_slot_3(ctx) {
	let start;
	let current;
	start = new Start({});

	return {
		c() {
			create_component(start.$$.fragment);
		},
		m(target, anchor) {
			mount_component(start, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(start.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(start.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(start, detaching);
		}
	};
}

// (59:6) <Route path="/project/*" let:params>
function create_default_slot_2(ctx) {
	let project;
	let current;
	const project_spread_levels = [/*params*/ ctx[2]];
	let project_props = {};

	for (let i = 0; i < project_spread_levels.length; i += 1) {
		project_props = assign(project_props, project_spread_levels[i]);
	}

	project = new Project({ props: project_props });

	return {
		c() {
			create_component(project.$$.fragment);
		},
		m(target, anchor) {
			mount_component(project, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const project_changes = (dirty & /*params*/ 4)
			? get_spread_update(project_spread_levels, [get_spread_object(/*params*/ ctx[2])])
			: {};

			project.$set(project_changes);
		},
		i(local) {
			if (current) return;
			transition_in(project.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(project.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(project, detaching);
		}
	};
}

// (63:6) <Route path="/camtest">
function create_default_slot_1(ctx) {
	let camtest;
	let current;
	camtest = new CamTest({});

	return {
		c() {
			create_component(camtest.$$.fragment);
		},
		m(target, anchor) {
			mount_component(camtest, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(camtest.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(camtest.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(camtest, detaching);
		}
	};
}

// (54:4) <Router bind:navigate library="{navaidHash}">
function create_default_slot(ctx) {
	let route0;
	let t0;
	let route1;
	let t1;
	let route2;
	let current;

	route0 = new Route({
			props: {
				path: "/",
				$$slots: { default: [create_default_slot_3] },
				$$scope: { ctx }
			}
		});

	route1 = new Route({
			props: {
				path: "/project/*",
				$$slots: {
					default: [
						create_default_slot_2,
						({ params }) => ({ 2: params }),
						({ params }) => params ? 4 : 0
					]
				},
				$$scope: { ctx }
			}
		});

	route2 = new Route({
			props: {
				path: "/camtest",
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(route0.$$.fragment);
			t0 = space();
			create_component(route1.$$.fragment);
			t1 = space();
			create_component(route2.$$.fragment);
		},
		m(target, anchor) {
			mount_component(route0, target, anchor);
			insert(target, t0, anchor);
			mount_component(route1, target, anchor);
			insert(target, t1, anchor);
			mount_component(route2, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const route0_changes = {};

			if (dirty & /*$$scope*/ 8) {
				route0_changes.$$scope = { dirty, ctx };
			}

			route0.$set(route0_changes);
			const route1_changes = {};

			if (dirty & /*$$scope, params*/ 12) {
				route1_changes.$$scope = { dirty, ctx };
			}

			route1.$set(route1_changes);
			const route2_changes = {};

			if (dirty & /*$$scope*/ 8) {
				route2_changes.$$scope = { dirty, ctx };
			}

			route2.$set(route2_changes);
		},
		i(local) {
			if (current) return;
			transition_in(route0.$$.fragment, local);
			transition_in(route1.$$.fragment, local);
			transition_in(route2.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(route0.$$.fragment, local);
			transition_out(route1.$$.fragment, local);
			transition_out(route2.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(route0, detaching);
			if (detaching) detach(t0);
			destroy_component(route1, detaching);
			if (detaching) detach(t1);
			destroy_component(route2, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let router;
	let updating_navigate;
	let current;

	function router_navigate_binding(value) {
		/*router_navigate_binding*/ ctx[1].call(null, value);
	}

	let router_props = {
		library: navaidHash,
		$$slots: { default: [create_default_slot] },
		$$scope: { ctx }
	};

	if (/*navigate*/ ctx[0] !== void 0) {
		router_props.navigate = /*navigate*/ ctx[0];
	}

	router = new Router({ props: router_props });
	binding_callbacks.push(() => bind(router, "navigate", router_navigate_binding));

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			create_component(router.$$.fragment);
			attr(div0, "class", "canvas svelte-t24l8e");
			set_style(div0, "background-image", "url(" + welcome + ")");
			attr(div1, "class", "w-full h-full flex flex-row justify-center align-middle");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			mount_component(router, div0, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const router_changes = {};

			if (dirty & /*$$scope*/ 8) {
				router_changes.$$scope = { dirty, ctx };
			}

			if (!updating_navigate && dirty & /*navigate*/ 1) {
				updating_navigate = true;
				router_changes.navigate = /*navigate*/ ctx[0];
				add_flush_callback(() => updating_navigate = false);
			}

			router.$set(router_changes);
		},
		i(local) {
			if (current) return;
			transition_in(router.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(router.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_component(router);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let navigate;

	onMount(() => {
		
	});

	function router_navigate_binding(value) {
		navigate = value;
		$$invalidate(0, navigate);
	}

	return [navigate, router_navigate_binding];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-t24l8e-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;