import 'p5';

export function checkIfProjectExists() {

}

export function pointsOnCircle(radius, angle, cx, cy) {
  let p = createVector(cx + radius * cos(angle), cy + radius * sin(angle));
  return p;
}

export function containsPoint(
  x,
  y,
  centerX,
  centerY,
  startingAngle,
  endingAngle,
  radius
) {
  let r = sqrt(pow(x - centerX, 2) + pow(y - centerY, 2));
  let a = atan2(y - centerY, x - centerX) + PI; //+PI to shift to a standard circle

  //Convert the angle to a standard trigonometric circle
  let s = (startingAngle + PI) % TWO_PI;
  let e = (endingAngle + PI) % TWO_PI;

  //Inspired by
  //https://stackoverflow.com/questions/6270785/how-to-determine-whether-a-point-x-y-is-contained-within-an-arc-section-of-a-c
  if (r < radius) {
    if (s < e) {
      if (a > s && a < e) {
        return true;
      }
    }
    if (s > e) {
      if (a > s || a < e) {
        return true;
      }
    }
  }
  return false;
}
