/* components/CamTest.svelte generated by Svelte v3.24.0 */
import { SvelteComponent, init, safe_not_equal } from "svelte/internal";

function instance($$self) {
	window.setup = function () {
		createCanvas(windowWidth, windowHeight, WEBGL);

		// the simplest method to enable the camera
		createEasyCam();

		// suppress right-click context menu
		document.oncontextmenu = function () {
			return false;
		};
	};

	window.draw = function () {
		background(64);
		lights();
		box(200);
	};

	return [];
}

class CamTest extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, null, safe_not_equal, {});
	}
}

export default CamTest;