/* components/ProjectSetup.svelte generated by Svelte v3.24.0 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_input_value,
	set_store_value,
	space
} from "svelte/internal";

import { getContext, onDestroy, onMount } from "svelte";
import slugify from "slugify";
import { project, projectSettings } from "../store.js";

import {
	getCollectionData,
	saveCollection,
	removeCollection
} from "../cmsConnector.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[22] = list[i];
	child_ctx[23] = list;
	child_ctx[24] = i;
	return child_ctx;
}

// (156:4) {#if deleting}
function create_if_block_2(ctx) {
	let div;
	let p;
	let t1;
	let button0;
	let t3;
	let button1;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			p = element("p");
			p.textContent = "Are you sure you want to delete the Project?";
			t1 = space();
			button0 = element("button");
			button0.textContent = "done";
			t3 = space();
			button1 = element("button");
			button1.textContent = "close";
			attr(p, "class", "text-white text-xl");
			attr(button0, "class", "btn-icon absolute bottom-0 left-0 m-4 material-icons");
			attr(button1, "class", "btn-icon absolute bottom-0 right-0 m-4 material-icons");
			attr(div, "class", "absolute mx-auto my-auto w-64 h-48 bg-app-green rounded-lg p-4 text-center");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, p);
			append(div, t1);
			append(div, button0);
			append(div, t3);
			append(div, button1);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler_1*/ ctx[12]),
					listen(button1, "click", /*click_handler_2*/ ctx[13])
				];

				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (177:4) {#if showLinkNotice}
function create_if_block_1(ctx) {
	let div;
	let p;
	let t1;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			p = element("p");
			p.textContent = "Please copy your project link and store it somewhere safe. Otherwise\n          the project can not be recovered!";
			t1 = space();
			button = element("button");
			button.textContent = "content_copy";
			attr(p, "class", "text-white text-xl");
			attr(button, "class", "btn-icon w-12 h-12 relative m-0 mt-6 material-icons");
			attr(div, "class", "absolute flex flex-col justify-center items-center w-64 bg-app-green rounded-lg p-4 text-center");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, p);
			append(div, t1);
			append(div, button);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler_3*/ ctx[14]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

// (218:8) {:else}
function create_else_block(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "add";
			attr(button, "class", "btn-icon mb-0 material-icons");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*addMember*/ ctx[9]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (213:8) {#if i < members.length - 1}
function create_if_block(ctx) {
	let button;
	let mounted;
	let dispose;

	function click_handler_5(...args) {
		return /*click_handler_5*/ ctx[18](/*i*/ ctx[24], ...args);
	}

	return {
		c() {
			button = element("button");
			button.textContent = "remove";
			attr(button, "class", "btn-icon mb-0 material-icons");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", click_handler_5);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (206:4) {#each members as m, i}
function create_each_block(ctx) {
	let div;
	let input;
	let t;
	let mounted;
	let dispose;

	function input_input_handler_1() {
		/*input_input_handler_1*/ ctx[17].call(input, /*each_value*/ ctx[23], /*i*/ ctx[24]);
	}

	function select_block_type(ctx, dirty) {
		if (/*i*/ ctx[24] < /*members*/ ctx[1].length - 1) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			div = element("div");
			input = element("input");
			t = space();
			if_block.c();
			attr(input, "class", "btn-text h-12 px-2 m-0");
			attr(input, "placeholder", "New Team Member");
			attr(div, "class", "flex flex-row items-center align-middle mb-4");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, input);
			set_input_value(input, /*m*/ ctx[22].name);
			append(div, t);
			if_block.m(div, null);

			if (!mounted) {
				dispose = listen(input, "input", input_input_handler_1);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*members*/ 2 && input.value !== /*m*/ ctx[22].name) {
				set_input_value(input, /*m*/ ctx[22].name);
			}

			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			if_block.d();
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let button0;
	let t1;
	let t2;
	let t3;
	let input;
	let t4;
	let button1;
	let t6;
	let h2;
	let t8;
	let t9;
	let button2;
	let mounted;
	let dispose;
	let if_block0 = /*deleting*/ ctx[3] && create_if_block_2(ctx);
	let if_block1 = /*showLinkNotice*/ ctx[2] && create_if_block_1(ctx);
	let each_value = /*members*/ ctx[1];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			button0 = element("button");
			button0.textContent = "delete";
			t1 = space();
			if (if_block0) if_block0.c();
			t2 = space();
			if (if_block1) if_block1.c();
			t3 = space();
			input = element("input");
			t4 = space();
			button1 = element("button");
			button1.textContent = "Copy Project Link";
			t6 = space();
			h2 = element("h2");
			h2.textContent = "Add Team Members";
			t8 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t9 = space();
			button2 = element("button");
			button2.textContent = "done";
			attr(button0, "class", "absolute top-0 right-0 m-4 btn-icon material-icons");
			attr(input, "class", "px-2 h-12 w-64 border-b-2 border-app-green text-app-green mt-24 mb-4");
			attr(input, "placeholder", "Project Name");
			attr(button1, "class", "btn-icon ml-0 mb-8 bg-app-green text-white w-48");
			attr(h2, "class", " font-bold uppercase mb-5");
			attr(button2, "class", "btn-icon w-12 h-12 m-0 mt-12 material-icons");
			attr(div0, "class", "absolute flex flex-col flex-grow justify-center items-center py-6 min-h-full w-full");
			attr(div1, "class", "relative w-full h-full");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, button0);
			append(div0, t1);
			if (if_block0) if_block0.m(div0, null);
			append(div0, t2);
			if (if_block1) if_block1.m(div0, null);
			append(div0, t3);
			append(div0, input);
			set_input_value(input, /*$project*/ ctx[4].project);
			append(div0, t4);
			append(div0, button1);
			append(div0, t6);
			append(div0, h2);
			append(div0, t8);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div0, null);
			}

			append(div0, t9);
			append(div0, button2);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler*/ ctx[11]),
					listen(input, "input", /*input_input_handler*/ ctx[15]),
					listen(button1, "click", /*click_handler_4*/ ctx[16]),
					listen(button2, "click", /*click_handler_6*/ ctx[19])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (/*deleting*/ ctx[3]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					if_block0.m(div0, t2);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*showLinkNotice*/ ctx[2]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					if_block1.m(div0, t3);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (dirty & /*$project*/ 16 && input.value !== /*$project*/ ctx[4].project) {
				set_input_value(input, /*$project*/ ctx[4].project);
			}

			if (dirty & /*removeMember, members, addMember*/ 770) {
				each_value = /*members*/ ctx[1];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div0, t9);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			destroy_each(each_blocks, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $project;
	let $projectSettings;
	component_subscribe($$self, project, $$value => $$invalidate(4, $project = $$value));
	component_subscribe($$self, projectSettings, $$value => $$invalidate(21, $projectSettings = $$value));
	let members = [{ name: "" }];
	let saving = false;
	let showLinkNotice = false;
	let { id } = $$props;
	let deleting = false;
	const navigate = getContext("navigate");

	function copyProjectUrl() {
		let url = `app.echo-tool.ch/#/project/${id}`;
		const elem = document.createElement("textarea");
		elem.value = url;
		document.body.appendChild(elem);
		elem.select();
		document.execCommand("copy");
		document.body.removeChild(elem);
		alert(`copied: ${url} to clipboard`);
	}

	async function deleteProject() {
		for (let i = 0; i < $project.users.length; i++) {
			await removeCollection("Users", $project.users[i]._id);
		}

		await removeCollection("Projects", $project._id);
		window.location.href = "/";
	}

	async function removeMember(index) {
		if (!$projectSettings.firstTime) {
			set_store_value(project, $project = await getCollectionData("Projects", { _id: id }, false));
			set_store_value(project, $project = $project.entries.pop());
		}

		$$invalidate(20, saving = true);
		$project.users.splice(index, 1);
		await saveCollection("Projects", $project);
		members.splice(index, 1);
		(($$invalidate(1, members), $$invalidate(20, saving)), $$invalidate(4, $project));
	}

	async function addMember() {
		if (!$projectSettings.firstTime) {
			set_store_value(project, $project = await getCollectionData("Projects", { _id: id }, false));
			set_store_value(project, $project = $project.entries.pop());
		}

		let name = members[members.length - 1].name;
		if (name == "") return;

		let result = await saveCollection("Users", {
			name,
			"name-slug": slugify(name, { locale: "de", lower: true }),
			data: [
				{
					x: 0.5,
					y: 0.5,
					color: { h: 0, s: 0, b: 100, a: 1 }
				}
			]
		});

		// get unpopulated project, so newly added user dont overwrite old users
		$project.users.push({ _id: result._id, link: "Users" });

		await saveCollection("Projects", $project);
		$$invalidate(1, members = members.concat({ name: "" }));
	}

	async function save() {
		if ($project.project == "") {
			alert("Enter Project Name");
			return;
		}

		$$invalidate(20, saving = true);
		await addMember();

		// refetch project collection to update Users
		await saveCollection("Projects", $project);

		set_store_value(project, $project = await getCollectionData("Projects", { _id: id }));
		set_store_value(project, $project = $project.entries.pop());

		if ($projectSettings.firstTime) {
			$$invalidate(2, showLinkNotice = true);
		} else {
			navigate("/project/" + id);
		}
	}

	onMount(() => {
		// getProject();
		if (members[0].name === "") {
			set_store_value(projectSettings, $projectSettings.firstTime = true, $projectSettings);
		}
	});

	onDestroy(() => {
		set_store_value(projectSettings, $projectSettings.firstTime = false, $projectSettings);
	});

	const click_handler = () => {
		$$invalidate(3, deleting = !deleting);
	};

	const click_handler_1 = () => deleteProject();

	const click_handler_2 = () => {
		$$invalidate(3, deleting = !deleting);
	};

	const click_handler_3 = () => {
		copyProjectUrl();
		navigate(`/project/${id}`);
	};

	function input_input_handler() {
		$project.project = this.value;
		project.set($project);
	}

	const click_handler_4 = () => copyProjectUrl();

	function input_input_handler_1(each_value, i) {
		each_value[i].name = this.value;
		(($$invalidate(1, members), $$invalidate(20, saving)), $$invalidate(4, $project));
	}

	const click_handler_5 = i => removeMember(i);
	const click_handler_6 = () => save();

	$$self.$set = $$props => {
		if ("id" in $$props) $$invalidate(0, id = $$props.id);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*saving, $project, members*/ 1048594) {
			$: if (!saving) {
				if ($project.users && !(members.length == $project.users.length + 1)) {
					$$invalidate(1, members = $project.users.concat(members));
				}
			}
		}
	};

	return [
		id,
		members,
		showLinkNotice,
		deleting,
		$project,
		navigate,
		copyProjectUrl,
		deleteProject,
		removeMember,
		addMember,
		save,
		click_handler,
		click_handler_1,
		click_handler_2,
		click_handler_3,
		input_input_handler,
		click_handler_4,
		input_input_handler_1,
		click_handler_5,
		click_handler_6
	];
}

class ProjectSetup extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { id: 0 });
	}
}

export default ProjectSetup;