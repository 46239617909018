export default function TopoUserData(user) {
    this.user = user;

    this.inputVerts = new Array(); 
    this.upperVerts = new Array();
    this.lowerVerts = new Array();
    this.startVert = new Array();
    this.nameVert = new Array();
    this.prevTopo = null;
    this.nextTopo = null;
    this.colors = new Array();
}
