/* components/TopoOverview.svelte generated by Svelte v3.24.0 */
import {
	SvelteComponent,
	add_render_callback,
	add_resize_listener,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	globals,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_store_value,
	space
} from "svelte/internal";

const { document: document_1 } = globals;
import { onMount, onDestroy, getContext } from "svelte";
import { topoData, project } from "../store";
import TopoUserData from "../TopoUserData.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-1tlejnu-style";
	style.textContent = "canvas{z-index:0;position:fixed;user-select:none}";
	append(document_1.head, style);
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let div0_resize_listener;
	let t0;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			t0 = space();
			button = element("button");
			button.textContent = "close";
			attr(div0, "id", "p5canvas");
			attr(div0, "class", "w-full h-full absolute z-50 rounded-lg");
			add_render_callback(() => /*div0_elementresize_handler*/ ctx[4].call(div0));
			attr(button, "class", "absolute top-0 right-0 m-4 btn-icon z-50 material-icons");
			attr(div1, "class", "w-full h-full relative z-50");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			div0_resize_listener = add_resize_listener(div0, /*div0_elementresize_handler*/ ctx[4].bind(div0));
			append(div1, t0);
			append(div1, button);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler*/ ctx[5]);
				mounted = true;
			}
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
			div0_resize_listener();
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $project;
	let $topoData;
	component_subscribe($$self, project, $$value => $$invalidate(9, $project = $$value));
	component_subscribe($$self, topoData, $$value => $$invalidate(10, $topoData = $$value));
	let { id } = $$props;
	const navigate = getContext("navigate");
	let canvasElement;
	let scaleFactor = { x: 30, y: 40 };
	let width;
	let height;
	let userCount;
	let inter;
	let easycam;

	window.preload = function () {
		inter = loadFont("../../../fonts/Rubik-Medium.ttf");
	};

	window.setup = function () {
		if (!$project.users) return;
		colorMode(HSL);

		// init(p5);
		textFont(inter);

		textSize(width / 3);
		textAlign(CENTER, CENTER);
		userCount = $project.users.length;
		set_store_value(topoData, $topoData = []);

		/*****************************************************/
		// Fill TopoData with user Data, so it is
		// availabe across project.
		// Replicate users, if users are less than 3
		/*****************************************************/
		if (userCount == 1) {
			userCount = 3;

			for (let i = 0; i < userCount; i++) {
				$topoData.push(new TopoUserData($project.users[0].name));

				for (let j = 0; j < $project.users[0].data.length; j++) {
					$topoData[i].inputVerts.push(createVector($project.users[0].data[j].x, $project.users[0].data[j].y));
					$topoData[i].colors.push(color($project.users[0].data[j].color.h, $project.users[0].data[j].color.s, $project.users[0].data[j].color.b, 1));
				}
			}
		} else if (userCount == 2) {
			userCount = 4;

			for (let i = 0; i < userCount; i++) {
				let modI = i % $project.users.length;
				$topoData.push(new TopoUserData($project.users[modI].name));

				for (let j = 0; j < $project.users[modI].data.length; j++) {
					$topoData[i].inputVerts.push(createVector($project.users[modI].data[j].x, $project.users[modI].data[j].y));
					$topoData[i].colors.push(color($project.users[modI].data[j].color.h, $project.users[modI].data[j].color.s, $project.users[modI].data[j].color.b, 1));
				}
			}
		} else {
			for (let i = 0; i < userCount; i++) {
				$topoData.push(new TopoUserData($project.users[i].name));

				for (let j = 0; j < $project.users[i].data.length; j++) {
					$topoData[i].inputVerts.push(createVector($project.users[i].data[j].x, $project.users[i].data[j].y));
					$topoData[i].colors.push(color($project.users[i].data[j].color.h, $project.users[i].data[j].color.s, $project.users[i].data[j].color.b, 1));
				}
			}
		}

		/*****************************************************/
		// Calculate all verticies for the Mountain
		/*****************************************************/
		for (let i = 0; i < userCount; i++) {
			// let verts = calculateUserShapes($topoData[i].inputVerts, i);
			let data = $topoData[i].inputVerts;

			let shift = i;

			// data.shift();
			data[0] = createVector(1, 0, 0);

			// data.push(createVector(0, 0, 0));
			// TODO //
			// Generate the whole shape here for all users, so it can be shaded
			// normalize x lenght to be always 1
			// reverse draw direction
			let verts = {
				upper: [],
				lower: [],
				start: null,
				name: null
			};

			let distRemainer = 0;
			verts.start = createVector((data.length + 1) * scaleFactor.x * sin(shift / userCount * 2 * PI), 0, (data.length + 1) * scaleFactor.x * cos(shift / userCount * 2 * PI));
			verts.name = createVector((data.length + 2) * scaleFactor.x * sin(shift / userCount * 2 * PI), 0, (data.length + 2) * scaleFactor.x * cos(shift / userCount * 2 * PI));

			// needs to start at length - 2, because of the added point for the plateau
			for (let i = data.length - 1, j = 0; i >= 0; (i--, j++)) {
				// rotation of vericies by cos found here:
				// https://stackoverflow.com/questions/14096138/find-the-point-on-a-circle-with-given-center-point-radius-and-degree
				// General formular is X = r * cosine(angle) , Y = r * sine(angle)
				// but since y is set, rotation happens around x and z
				// r = (i + 1) * scaleFactor
				// angle is calculated based on numbers of users sin((j / userCount) * 2 * Math.PI)
				let dist = data[i].x;

				let arrayChunk = data.slice(0, j + 1);

				let accumulatedTopoHeight = -arrayChunk.reduce(
					(accumulator, currentValue) => {
						return accumulator + currentValue.y;
					},
					0
				);

				let lowerVertex = createVector((i - dist + distRemainer) * scaleFactor.x * sin(shift / userCount * 2 * PI), 0, (i - dist + distRemainer) * scaleFactor.x * cos(shift / userCount * 2 * PI));
				verts.lower.push(lowerVertex);
				let upperVertex = createVector((i + dist) * scaleFactor.x * sin(shift / userCount * 2 * PI), accumulatedTopoHeight * scaleFactor.y, (i + dist) * scaleFactor.x * cos(shift / userCount * 2 * PI));
				verts.upper.push(upperVertex);

				// if week was not sucessful, add the remaining part to the next week
				distRemainer = dist < 1 ? 1 - dist : 0;
			} // if ($topoData.x < 1) {
			//   xRemainer = 1 - $topoData.x;

			// }
			set_store_value(topoData, $topoData[i].upperVerts = verts.upper, $topoData);

			set_store_value(topoData, $topoData[i].lowerVerts = verts.lower, $topoData);
			set_store_value(topoData, $topoData[i].startVert = verts.start, $topoData);
			set_store_value(topoData, $topoData[i].nameVert = verts.name, $topoData);
		}

		/*****************************************************/
		// Concatenate all Users together
		/*****************************************************/
		for (let i = 0; i < userCount; i++) {
			if (i == 0) {
				set_store_value(topoData, $topoData[i].prevTopo = $topoData[userCount - 1], $topoData);
				set_store_value(topoData, $topoData[i].nextTopo = $topoData[i + 1], $topoData);
			} else if (i == userCount - 1) {
				set_store_value(topoData, $topoData[i].prevTopo = $topoData[i - 1], $topoData);
				set_store_value(topoData, $topoData[i].nextTopo = $topoData[0], $topoData);
			} else {
				set_store_value(topoData, $topoData[i].prevTopo = $topoData[i - 1], $topoData);
				set_store_value(topoData, $topoData[i].nextTopo = $topoData[i + 1], $topoData);
			}
		}

		/*****************************************************/
		// Create Canvas and easycam
		/*****************************************************/
		// calculate longest distance between name points to set zoom scale
		let maxDistance = 0;

		let maxInputs = 0;

		for (let i = 0; i < userCount; i++) {
			maxInputs = $topoData[i].upperVerts.length > maxInputs
			? $topoData[i].upperVerts.length
			: maxInputs;

			for (let j = 0; j < $topoData.length; j++) {
				let vecA = createVector($topoData[i].nameVert.x, $topoData[i].nameVert.y, $topoData[i].nameVert.z);
				let vecB = createVector($topoData[j].nameVert.x, $topoData[j].nameVert.y, $topoData[j].nameVert.z);
				let distance = vecA.dist(vecB);
				maxDistance = distance > maxDistance ? distance : maxDistance;
			}
		}

		console.log(maxInputs);

		// calculate center of points
		let center = createVector(0, 0, 0);

		for (let i = 0; i < userCount; i++) {
			let vec = createVector($topoData[i].nameVert.x, $topoData[i].nameVert.y, $topoData[i].nameVert.z);
			center.add(vec);
		}

		let canvas = createCanvas(width, height, WEBGL);
		canvas.parent("p5canvas");

		easycam = createEasyCam({
			distance: maxDistance + maxInputs * 10 + 250
		});

		easycam.rotateX(1);

		// easycam.pan(center.x, center.y);
		setAttributes("antialias", true);

		// where is this one coming from?
		let iframe = document.getElementsByTagName("iframe")[0];

		iframe.style.display = "none";
	};

	window.draw = function () {
		if (!$project.users) return;
		background(108, 43, 88);
		ambientLight(0, 0, 20);
		directionalLight(0, 0, 100, 1, 0, 1000, 500);

		// debugMode();
		/*****************************************************/
		// Draw sides of the mountain
		/*****************************************************/
		push();

		fill(25);
		noStroke();

		for (let i = 0; i < userCount; i++) {
			let vertsCurrent = $topoData[i].upperVerts.length - 1;
			let vertsPrevious = $topoData[i].prevTopo.upperVerts.length - 1;

			let maxVerts = vertsCurrent > vertsPrevious
			? vertsCurrent
			: vertsPrevious;

			let index = 0;
			beginShape(TRIANGLE_STRIP);

			for (let j = 0; j <= maxVerts; j++) {
				index = j <= vertsCurrent ? j : vertsCurrent;
				vertex($topoData[i].upperVerts[index].x, $topoData[i].upperVerts[index].y, $topoData[i].upperVerts[index].z);
				index = j <= vertsPrevious ? j : vertsPrevious;
				vertex($topoData[i].prevTopo.upperVerts[index].x, $topoData[i].prevTopo.upperVerts[index].y, $topoData[i].prevTopo.upperVerts[index].z);
			}

			endShape();
		}

		pop();

		/*****************************************************/
		// Draw connecting lines between verticies
		/*****************************************************/
		push();

		// fill(25);
		noFill();

		// noStroke();
		strokeWeight(1);

		stroke(25);

		for (let i = 0; i < userCount; i++) {
			let vertsCurrent = $topoData[i].upperVerts.length - 1;
			let vertsPrevious = $topoData[i].prevTopo.upperVerts.length - 1;

			let maxVerts = vertsCurrent > vertsPrevious
			? vertsCurrent
			: vertsPrevious;

			let index = 0;
			beginShape(LINES);

			for (let j = 0; j <= maxVerts; j++) {
				index = j <= vertsCurrent ? j : vertsCurrent;
				vertex($topoData[i].upperVerts[index].x, $topoData[i].upperVerts[index].y, $topoData[i].upperVerts[index].z);
				index = j <= vertsPrevious ? j : vertsPrevious;
				vertex($topoData[i].prevTopo.upperVerts[index].x, $topoData[i].prevTopo.upperVerts[index].y, $topoData[i].prevTopo.upperVerts[index].z);
			}

			endShape();
		}

		pop();

		/*****************************************************/
		// Draw closing base of mountain
		/*****************************************************/
		push();

		fill(25);
		noStroke();
		beginShape();

		for (let i = 0; i < userCount; i++) {
			vertex($topoData[i].upperVerts[0].x, $topoData[i].upperVerts[0].y, $topoData[i].upperVerts[0].z);
		}

		endShape();
		pop();

		/*****************************************************/
		// Draw plateau on top
		/*****************************************************/
		push();

		fill(100);
		noStroke();
		beginShape();

		for (let i = 0; i < userCount; i++) {
			let index = $topoData[i].upperVerts.length - 1;
			vertex($topoData[i].upperVerts[index].x, $topoData[i].upperVerts[index].y, $topoData[i].upperVerts[index].z);
		}

		endShape();
		pop();

		/*****************************************************/
		// Draw Outlines of the Mountain
		/*****************************************************/
		push();

		for (let i = 0; i < userCount; i++) {
			// // draw the outlines from start to the first mountain point
			push();

			strokeWeight(5);
			stroke(0, 0, 0, 0);
			line($topoData[i].startVert.x, $topoData[i].startVert.y, $topoData[i].startVert.z, $topoData[i].upperVerts[0].x, $topoData[i].upperVerts[0].y, $topoData[i].upperVerts[0].z);
			translate($topoData[i].startVert.x, $topoData[i].startVert.y, $topoData[i].startVert.z);
			sphere(3);
			pop();

			for (let j = 0; j < $topoData[i].upperVerts.length - 1; j++) {
				if (j == $topoData[i].upperVerts.length - 1) break;
				strokeWeight(5);
				stroke($topoData[i].colors[j + 1]);
				line($topoData[i].upperVerts[j].x, $topoData[i].upperVerts[j].y, $topoData[i].upperVerts[j].z, $topoData[i].upperVerts[j + 1].x, $topoData[i].upperVerts[j + 1].y, $topoData[i].upperVerts[j + 1].z);
				push();
				noStroke();
				ambientMaterial($topoData[i].colors[j + 1]);
				translate($topoData[i].upperVerts[j].x, $topoData[i].upperVerts[j].y, $topoData[i].upperVerts[j].z);
				sphere(3);
				pop();
			}
		}

		pop();

		/*****************************************************/
		// Draw outlines plateau
		/*****************************************************/
		// push();
		// fill(100);
		// strokeWeight(5);
		// stroke(0, 0, 0, 0);
		// for (let i = 0; i < userCount; i++) {
		//   let index1 = $topoData[i].upperVerts.length - 1;
		//   let index2 = $topoData[i].prevTopo.upperVerts.length - 1;
		//   line(
		//     $topoData[i].upperVerts[index1].x,
		//     $topoData[i].upperVerts[index1].y,
		//     $topoData[i].upperVerts[index1].z,
		//     $topoData[i].prevTopo.upperVerts[index2].x,
		//     $topoData[i].prevTopo.upperVerts[index2].y,
		//     $topoData[i].prevTopo.upperVerts[index2].z
		//   );
		//   push();
		//   translate(
		//     $topoData[i].upperVerts[index1].x,
		//     $topoData[i].upperVerts[index1].y,
		//     $topoData[i].upperVerts[index1].z
		//   );
		//   sphere(2);
		//   pop();
		// }
		// pop();
		/*****************************************************/
		// Draw Names of users
		/*****************************************************/
		for (let i = 0; i < userCount; i++) {
			push();
			fill(0);
			textFont(inter);
			textSize(width / 5 / userCount);
			textAlign(CENTER, CENTER);
			translate($topoData[i].nameVert.x, $topoData[i].nameVert.y, $topoData[i].nameVert.z);
			rotateX(HALF_PI);
			rotateZ(-(TWO_PI / (userCount / i)));
			text($topoData[i].user, 0, 0);
			pop();
		}
	};

	onMount(() => {
		new p5();
	});

	onDestroy(() => {
		delete window.setup;
		delete window.draw;
		delete window.preload;
		delete window.windowResized;
		delete window.mousePressed;
		delete window.mouseReleased;
		remove();
	});

	function div0_elementresize_handler() {
		height = this.offsetHeight;
		width = this.offsetWidth;
		$$invalidate(2, height);
		$$invalidate(1, width);
	}

	const click_handler = () => {
		navigate(`/project/${id}`);
	};

	$$self.$set = $$props => {
		if ("id" in $$props) $$invalidate(0, id = $$props.id);
	};

	return [id, width, height, navigate, div0_elementresize_handler, click_handler];
}

class TopoOverview extends SvelteComponent {
	constructor(options) {
		super();
		if (!document_1.getElementById("svelte-1tlejnu-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { id: 0 });
	}
}

export default TopoOverview;